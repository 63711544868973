import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  IconButton,
  Link,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import React, { useRef } from 'react'
import { Copy, HelpCircle, Menu, RefreshCw } from 'react-feather'
import { useParams } from 'react-router-dom'
import { v4 as uuid4 } from 'uuid'

export const TmwygtMenu = ({ buttonColor }: { buttonColor: string }) => {
  const { mapId } = useParams()
  const menuRef = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const copyMapUrl = () => {
    navigator.clipboard.writeText(`https://${window.location.host}/${mapId}`)
  }

  return (
    <Grid>
      <IconButton aria-label='menu' onClick={onOpen} ref={menuRef} icon={<Menu />} className='tmwygt-menu-button'
        color={buttonColor} />
      <Drawer isOpen={isOpen} placement='left' onClose={onClose} finalFocusRef={menuRef} size='md'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Text Me When You Get There!</DrawerHeader>
          <DrawerBody>
            <Link onClick={copyMapUrl} className='tmwygt-menu-item'>
              <Flex gap={3}>
                <Copy />
                <Text>Copy Map URL</Text>
              </Flex>
            </Link>
            <Link href={`/map/${uuid4()}`} className='tmwygt-menu-item'>
              <Flex gap={3}>
                <RefreshCw />
                <Text>New Map</Text>
              </Flex>
            </Link>
            <Link href='/about' className='tmwygt-menu-item'>
              <Flex gap={3}>
                <HelpCircle />
                <Text>How does it work?</Text>
              </Flex>
            </Link>
          </DrawerBody>
        </DrawerContent>

      </Drawer>

    </Grid>
  )
}