import React, { useEffect, useState, ReactElement } from "react";
import { v4 as uuid4 } from 'uuid'
import Map, { NavigationControl, Marker, useMap, LngLatBoundsLike } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { TmwygtMenu } from "./TmwygtMenu";
import { TmwygtAvatar } from "./TmwygtAvatar";

interface Position {
  name: string
  lat: number
  lon: number
}

export const TmywgtMap = () => {
  const { mapId } = useParams()
  const [markers, setMarkers] = useState<ReactElement[]>([])
  const [boundingBox, setBoundingBox] = useState<LngLatBoundsLike | null>(null)
  let name = localStorage.getItem('tmwygtName')
  if (!name) {
    name = uuid4()
    localStorage.setItem('tmwygtName', name)
  }

  const getPosition = () => {
    navigator.geolocation.getCurrentPosition(fetchMarkers)
  }

  const fetchMarkers = async (position: GeolocationPosition) => {
    const resp = await fetch(`/api/${mapId}`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        lat: position.coords.latitude,
        lon: position.coords.longitude
      })
    })
    const data = await resp.json()
    setMarkers(data.map((d: Position) => {
      return (
        <Marker longitude={d.lon} latitude={d.lat} key={d.name}>
          <TmwygtAvatar markerName={d.name} />
        </Marker>
      )
    }))
    let minLat = 90
    let maxLat = -90
    let minLng = 180
    let maxLng = -180
    data.forEach((d: Position) => {
      minLat = Math.min(minLat, d.lat)
      maxLat = Math.max(maxLat, d.lat)
      minLng = Math.min(minLng, d.lon)
      maxLng = Math.max(maxLng, d.lon)
    })
    const latDiff = Math.max(maxLat - minLat, 0.05)
    const lngDiff = Math.max(maxLng - minLng, 0.05)
    setBoundingBox([
      [minLng - (lngDiff * 0.2), minLat - (latDiff * 0.2)],
      [maxLng + (lngDiff * 0.2), maxLat + (latDiff * 0.2)]
    ])
  }

  useEffect(() => {
    const interval = setInterval(getPosition, 15000)
    getPosition()
    return () => clearInterval(interval)
  }, [])

  return (
    <Stack w='100%' h='100%'>
      <Map
        initialViewState={{
          longitude: -95,
          latitude: 40,
          zoom: 4
        }}
        style={{
          height: '100%',
          width: '100%',
          overflow: 'hidden'
        }}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        cursor="default"
      >
        <TmwygtMenu buttonColor="#2D3748" />
        {markers}
        <NavigationControl position="top-right" />
        <MapZoomHandler boundingBox={boundingBox} />
      </Map>

    </Stack>
  )
}

const MapZoomHandler = ({ boundingBox }: { boundingBox: LngLatBoundsLike | null }) => {
  const map = useMap()
  useEffect(() => {
    if (map.current && boundingBox) {
      map.current.getMap()?.fitBounds(boundingBox)
    }
  }, [boundingBox])
  return <></>
}