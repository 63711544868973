import React from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import { About } from './About';
import { TmywgtMap } from './TmwygtMap';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/about' replace />} />
      <Route path='/about' element={<About />} />
      <Route path="/map/:mapId" element={<TmywgtMap />} />
    </Routes>
  );
}

export default App;
