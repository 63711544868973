import React, { CSSProperties } from "react";

const hashcode = (s: string) => {
  let hash = 0,
    i, chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0;
  }
  return hash;
}

export const TmwygtAvatar = ({ markerName }: { markerName: string }) => {
  const names = [
    "alligator",
    "anteater",
    "armadillo",
    "auroch",
    "axolotl",
    "badger",
    "bat",
    "beaver",
    "buffalo",
    "camel",
    "capybara",
    "chameleon",
    "cheetah",
    "chinchilla",
    "chipmunk",
    "chupacabra",
    "cormorant",
    "coyote",
    "crow",
    "dingo",
    "dinosaur",
    "dolphin",
    "duck",
    "elephant",
    "ferret",
    "fox",
    "frog",
    "giraffe",
    "gopher",
    "grizzly",
    "hedgehog",
    "hippo",
    "hyena",
    "ibex",
    "ifrit",
    "iguana",
    "jackal",
    "kangaroo",
    "koala",
    "kraken",
    "lemur",
    "leopard",
    "liger",
    "llama",
    "manatee",
    "mink",
    "monkey",
    "moose",
    "narwhal",
    "orangutan",
    "otter",
    "panda",
    "penguin",
    "platypus",
    "pumpkin",
    "python",
    "quagga",
    "rabbit",
    "raccoon",
    "rhino",
    "sheep",
    "shrew",
    "skunk",
    "squirrel",
    "tiger",
    "turtle",
    "walrus",
    "wolf",
    "wolverine",
    "wombat"
  ];

  const colors = [
    "#FF0044",
    "#006CFE",
    "#FFCC41",
    "#B476FB",
    "#FE9D24",
    "#29B278",
    "#00D7BF"
  ];

  const hash = hashcode(markerName)
  const name = names[Math.abs(hash % (names.length - 1))]
  const color = colors[Math.abs(hash % (colors.length - 1))]

  const avatarStyle = {
    "--a-bg-color": color
  } as CSSProperties

  return (
    <div className="tmwygt-avatar" style={avatarStyle}>
      <img src={`/avatars/${name}.png`} alt={name || ''} className="tmwygt-animal-image" />
    </div>
  )
}