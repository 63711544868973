/* eslint-disable max-len */
import { Heading, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { TmwygtMenu } from './TmwygtMenu'

export const About = () => {
  return (
    <Stack>
      <TmwygtMenu buttonColor='white' />
      <Stack margin='auto' width={['90%', '60%']} pt={['10%', 0]} gap={5}>
        <Heading>What is Text Me When You Get There?</Heading>
        <Text>
          Text Me When You Get There is an app inspired by my own forgetfulness to inform my partner that I've arrived safely at my destination.
        </Text>
        <Heading>How does it work?</Heading>
        <Text>
          When you open the application to the map (e.g. <code>/map/e1d2b319-b66c-48c6-83ae-a1022513fe49</code>), you begin sharing your location. This information is anonymized and only persists for 60s. It is <i>not</i> secured behind a password, but is keyed by the map ID (the UUID in the URL). <b>Anyone with this URL can see your location!</b>, and anyone viewing the same URL will have the option to share their own location. This is how it works. By loading a new map and sharing the URL you will effectively be sharing your location with anyone else who also loads the same URL for as long as you keep the application open. When you close the application, you will stop sharing your location. Simple as pie.
        </Text>
        <Heading>How can I trust you with my location data?</Heading>
        <Text>
          This question is, at the heart of it, why I built this application rather than using the many free alternatives out there. And the simple answer is [you can see all the code I'm using](https://gitlab.com/anfreitas/tmwygt). And the supplementary answer to that question is I have neither the time nor the inclination to do anything with anyone's location data; I just don't care. And if you still don't trust or believe that but still like the idea/application, feel free to clone this repo and stand up your own. The instructions are in the [README](https://gitlab.com/anfreitas/tmwygt/-/blob/main/README.md).
        </Text>
      </Stack>
    </Stack>
  )
}